import { Component, OnInit } from '@angular/core';
import { ApiService, Status } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Survey } from '../_model/survey.model';
import { Question } from '../_model/question.model';
import { Answer } from '../_model/answer.model';
import { Submission } from '../_model/submission.model';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  public surveyId: string = null;

  protected survey: Survey = null;
  protected answers: Answer[] = null;

  protected currentAnswerValue = null;

  protected error = false;
  protected saving = false;
  public finished = false;

  protected currentIndex = 0;
  protected metadata = {
    role: null,
    unit: null
  };

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if ('surveyId' in params) {
        this.surveyId = params.surveyId;

        this.api.getSurvey(this.surveyId).subscribe(
          (survey: Survey) => {
            this.survey = survey;

            this.answers = [];
            for (const question of this.survey.questions) {
              this.answers.push({
                questionId: question.id,
                value: null,
                comment: null
              });
            }
          },
          (_error) => {
            this.survey = null;
            this.error = true;
          }
        );
      } else {
        this.surveyId = null;
      }
    });
  }

  canGoBack() {
    return this.currentIndex > 0;
  }

  canGoNext() {
    return this.currentIndex <= this.survey.questions.length;
  }

  isLast() {
    return this.currentIndex === this.survey.questions.length;
  }

  goBack() {
    if (this.canGoBack()) {
      this.storeAnswerValue();
      this.currentIndex--;
      this.setAnswerValue();
    }
  }

  goNext() {
    if (this.canGoNext()) {
      this.storeAnswerValue();
      this.currentIndex++;
      this.setAnswerValue();
    }
  }

  finish() {
    this.storeAnswerValue();
    this.saving = true;
    this.error = false;
    this.finished = true;

    const submission: Submission = {
      metadata: this.metadata,
      answers: this.answers
    };

    this.api.saveSurveySubmission(this.surveyId, submission).subscribe(
      (status: Status) => {
        this.saving = false;
        if (status.error) {
          this.error = true;
        }
      },
      (_error) => {
        this.saving = false;
        this.error = true;
      }
    );
  }

  updateAnswerValue() {
    this.currentAnswerValue = this.getSelectedAnswer();
  }

  storeAnswerValue() {
    if (this.currentIndex > 0) {
      this.answers[this.currentIndex-1].value = this.getSelectedAnswer();
    }
  }


  setAnswerValue() {
    if (this.currentIndex > 0) {
      this.currentAnswerValue = this.answers[this.currentIndex-1].value;
    } else {
      this.currentAnswerValue = null;
    }

    const elements = document.getElementsByName('answer');
    elements.forEach((element: HTMLInputElement) => {
      if (element.value === this.currentAnswerValue) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  getSelectedAnswer() {
    const elements = document.getElementsByName('answer');
    let selectedAnswerValue = null;
    elements.forEach((element: HTMLInputElement) => {
      if (element.checked) {
        selectedAnswerValue = element.value;
      }
    });

    return selectedAnswerValue;
  }
}
