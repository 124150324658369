import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  public surveyId: string = null;

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        console.log(this.route.queryParams);
        if ('survey' in params) {
          this.surveyId = params.survey;
        } else {
          this.surveyId = null;
        }
    });
  }

  startSurvey() {
    this.router.navigate([ '/survey/' + this.surveyId ]);
  }
}
