import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Survey } from '../_model/survey.model';
import { Submission } from '../_model/submission.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) {}

  public getSurvey(surveyId: string): Observable<Survey> {
    return this.httpClient.get<any>('/api/surveys/' + surveyId);
  }

  public saveSurveySubmission(surveyId: string, submission: Submission): Observable<Status> {
    return this.httpClient.post<any>('/api/surveys/' + surveyId + '/submission', submission);
  }
}

export interface Status {
  message?: string;
  error: boolean;
}
