import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from './portal/portal.component';
import { SurveyComponent } from './survey/survey.component';

const routes: Routes = [
  { path: 'portal', component: PortalComponent },
  { path: 'survey/:surveyId', component: SurveyComponent },
  { path: '', redirectTo: '/portal', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
